import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingToRedirect from './LoadingToRedirect';
import { currentStudent } from '../../functions/auth';

const StudentRoute = ({ children, ...rest }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      //console.log("Fetching current student with token:", user.token);
      currentStudent(user.token)
        .then((res) => {
          console.log("CURRENT STUDENT RESPONSE");
          setOk(true);
        })
        .catch((err) => {
          console.log("STUDENT ROUTE ERROR", err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Route {...rest} /> : <LoadingToRedirect />;
};

export default StudentRoute;
