import axios from "axios";
export const userAuthentication = async (authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> auth -> userAuthentication");
  return await axios.post(
    `${process.env.REACT_APP_API}/createOrUpdateUser`, //making request to endpoing createOrUpdateUser and sending firebase authtoken in header
    {},
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
};

export const currentUser = async (authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> auth -> currentUser");
  return await axios.post(
    `${process.env.REACT_APP_API}/current-user`, //making request to endpoing current-user and sending firebase authtoken in header
    {},
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
};

export const currentAdmin = async (authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> auth -> currentAdmin");

  return await axios.post(
    `${process.env.REACT_APP_API}/current-admin`, //making request to endpoing current-user and sending firebase authtoken in header
    {},
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
};

export const currentStudent = async (authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> auth -> currentStudent");

  return await axios.post(
    `${process.env.REACT_APP_API}/current-student`, //making request to endpoing current-user and sending firebase authtoken in header
    {},
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
};
