export const ADD_TO_CART = "SET_CART_ITEMS"; // Keep this for consistency

// Add item to cart action
export const addToCart = (items) => {
  return {
    type: "SET_CART_ITEMS", // Use "SET_CART_ITEMS" here for consistency
    payload: items, // 'items' should be the updated cart array
  };
};

// Optional: Load cart from storage if needed when app initializes
export const loadCart = () => {
  const storedCart = localStorage.getItem("cart");
  return {
    type: "SET_CART_ITEMS",
    payload: storedCart ? JSON.parse(storedCart) : [],
  };
};
