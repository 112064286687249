import axios from "axios";

export const askChatBot = async (question, subjectId, chapterId, topicId, authtoken) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> chatbot -> askChatBot",subjectId, chapterId, topicId);

    const response = await axios.post(`${process.env.REACT_APP_API}/chatbot`, {question, subjectId, chapterId, topicId}, {
      headers: {
        authtoken,
      },
    });
    console.log("FRONTEND AXIOS FUNCTION NAME -> chatbot -> askChatBot -> response",response);
    return response.data; // Expecting { answer: "LLM response here" }
  } catch (error) {
    console.error("Chatbot API error:", error);
    return { answer: "Sorry, I couldn't process your request." };
  }
};

export const checkRedisStatus = async () => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API}/redis-status`);
    return data.redisUp; // Returns true or false
  } catch (error) {
    console.error("❌ Redis status check failed:", error.response?.data || error.message);
    return false; // Assume Redis is down if API call fails
  }
};