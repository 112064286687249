import React, { useEffect,lazy, Suspense } from "react";
import { Switch, Route,useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currentUser } from "./functions/auth";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { loadCart } from "./pages/Cart/cartActions.js";
import ChatBot from "./components/chat/ChatBot.js";
import ErrorBoundary from './components/ErrorBoundary';


import './index.css';
// Admin user check component import
import AdminRoute from './components/routes/AdminRoute.js';
// Student user check component import
import StudentRoute from './components/routes/StudentRoute.js';
import { LoadingOutlined } from "@ant-design/icons";
//import MySubscriptions from "./pages/students/MySubscriptions.js";

// General Pages import
// import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
// import Home from "./pages/Home";
// import RegisterComplete from "./pages/auth/RegisterComplete";
// import ForgotPassword from "./pages/auth/ForgotPassword";
// import Header from "./components/nav/Header";
// import Sidebar from "./components/nav/adminnav/Sidebar";
// import BoardList from "./pages/Board";
// import BoardPage from "./pages/BoardPage";
// import ClassList from "./pages/Class";
// import ClassPage from "./pages/ClassPage";
// import SubjectList from "./pages/Subject";
// import SubjectPage from "./pages/SubjectPage";
// import SubjectDetailPage from "./pages/SubjectDetailPage";
// import ChapterList from "./pages/Chapter";
// import ChapterPage from "./pages/ChapterPage";
// import TopicList from "./pages/Topic";
// import TopicPage from "./pages/TopicPage";
// import ContentList from "./pages/Content";
// import ContentPage from "./pages/ContentPage";
// import BookList from "./pages/book.js";
// import BookPage from "./pages/BookPage";
// import ExamList from "./pages/exam.js";
// import Exam from "./pages/ExamPage.js";
// import ExamQuestion from "./pages/ExamQuestions.js";
// import BookSolutionPage from "./pages/BookSolution.js";
// import QuestionList from './pages/questionList.js';
// import UserQuestionsPage from './pages/userQuestionsPage.js';
// import UserQuestion from './pages/userQuestionPage.js';
// import Subscribe from './pages/Subscribe.js';
// //Importing Lazy
// const Login = lazy(()=> import("./pages/auth/Login"));

// // Students Pages import
// import Onboarding from './pages/students/Onboarding.js';
// import EditPreferences from './pages/students/EditPreferences.js';
// import MyCourses from './pages/students/MyCourses.js';
// import MyNotes from './pages/students/MyNotes.js';
// import MySettings from './pages/students/MySettings.js';
// import PasswordReset from './pages/students/PasswordReset.js';
// //import QuestionList from "./pages/students/QuestionList";
// //import QuestionDetail from "./pages/students/QuestionDetail";
// //import QuestionForm from "./pages/students/QuestionForm";



// // Admin Pages import
// import AdminDashboard from './pages/admin/adminDashboard.js';
// import BoardCreate from './pages/admin/k12/board/CreateBoard';
// import ClassCreate from './pages/admin/k12/class/ClassCreate';
// import BoardUpdate from './pages/admin/k12/board/UpdateBoard';
// import ClassUpdate from './pages/admin/k12/class/UpdateClass';
// import SubjectCreate from './pages/admin/k12/subject/SubjectCreate.js';
// import SubjectUpdate from './pages/admin/k12/subject/UpdateSubject.js';
// import ChapterCreate from './pages/admin/k12/chapter/ChapterCreate.js';
// import ChapterUpdate from './pages/admin/k12/chapter/ChapterUpdate.js';
// import TopicCreate from './pages/admin/k12/topic/TopicCreate.js';
// import UpdateTopic from './pages/admin/k12/topic/TopicUpdate.js';
// import ContentCreate from './pages/admin/k12/content/ContentCreate.js';
// import ContentUpdate from './pages/admin/k12/content/ContentUpdate.js';
// import BookCreate from './pages/admin/k12/book/CreateBook.js';
// import UpdateBook from './pages/admin/k12/book/UpdateBook.js';
// import CreateBookChapter from './pages/admin/k12/chapter/CreateBookChapter.js';
// import ExamCreate from './pages/admin/k12/exam/CreateExam.js';
// import CreateQuestion from './pages/admin/k12/question/CreateQuestions.js';
// import CreateQuestionCsv from './pages/admin/k12/question/CreateQuestionCsv.js';
// import ExamYear from "./pages/admin/k12/exam/CreateExamYear.js";

const Login =lazy(()=> import( "./pages/auth/Login"));
const Register =lazy(()=> import( "./pages/auth/Register"));
const Home =lazy(()=> import( "./pages/Home"));
const RegisterComplete =lazy(()=> import( "./pages/auth/RegisterComplete"));
const ForgotPassword =lazy(()=> import( "./pages/auth/ForgotPassword"));
const Header =lazy(()=> import( "./components/nav/Header"));
const Sidebar =lazy(()=> import( "./components/nav/adminnav/Sidebar"));
const BoardList =lazy(()=> import( "./pages/Board"));
const BoardPage =lazy(()=> import( "./pages/BoardPage"));
const ClassList =lazy(()=> import( "./pages/Class"));
const Checkout =lazy(()=> import( "./pages/Checkout"));
const ClassPage =lazy(()=> import( "./pages/ClassPage"));
const SubjectList =lazy(()=> import( "./pages/Subject"));
const SubjectPage =lazy(()=> import( "./pages/SubjectPage"));
const SubjectDetailPage =lazy(()=> import( "./pages/SubjectDetailPage"));
const ChapterList =lazy(()=> import( "./pages/Chapter"));
const ChapterPage =lazy(()=> import( "./pages/ChapterPage"));
const TopicList =lazy(()=> import( "./pages/Topic"));
const TopicPage =lazy(()=> import( "./pages/TopicPage"));
const ContentList =lazy(()=> import( "./pages/Content"));
const ContentPage =lazy(()=> import( "./pages/ContentPage"));
const BookList =lazy(()=> import( "./pages/book.js"));
const BookPage =lazy(()=> import( "./pages/BookPage"));
const ExamList =lazy(()=> import( "./pages/exam.js"));
const Exam =lazy(()=> import( "./pages/ExamPage.js"));
const ExamQuestion =lazy(()=> import( "./pages/ExamQuestions.js"));
const BookSolutionPage =lazy(()=> import( "./pages/BookSolution.js"));
const QuestionList =lazy(()=> import( './pages/questionList.js'));
const UserQuestionsPage =lazy(()=> import( './pages/userQuestionsPage.js'));
const UserQuestion =lazy(()=> import( './pages/userQuestionPage.js'));
const Subscribe =lazy(()=> import( './pages/Subscribe.js'));
const Payment =lazy(()=> import( './pages/Payment.js'));
const Cart =lazy(()=> import( './pages/Cart/Cart.js'));
const MockTests =lazy(()=> import( './pages/MockTests.js'));
const MockTestsPreview =lazy(()=> import( './pages/test/testPreview/TestPreview.js'));
const MockTestsInstructions =lazy(()=> import( './pages/test/testPreview/TestInstructions.js'));
const MockTestResults =lazy(()=> import( './pages/students/MockTestResults.js'));
const DocsPage =lazy(()=> import( './pages/DocPage.js'));
//Importing Lazy


// Students Pages const
const Onboarding =lazy(()=> import( './pages/students/Onboarding.js'));
const EditPreferences =lazy(()=> import( './pages/students/EditPreferences.js'));
const MyCourses =lazy(()=> import( './pages/students/MyCourses.js'));
const MyNotes =lazy(()=> import( './pages/students/MyNotes.js'));
const MyQuestions =lazy(()=> import( './pages/students/MyQuestions.js'));
const MySettings =lazy(()=> import( './pages/students/MySettings.js'));
const PasswordReset =lazy(()=> import( './pages/students/PasswordReset.js'));
const History =lazy(()=> import( './pages/students/OrderHistory.js'));
const AttemptTest =lazy(()=> import( './pages/test/testPreview/HandleLiveTest.js'));
const TestResults =lazy(()=> import( './pages/test/result/TestResultsPage.js'));
const Profile =lazy(()=> import( './pages/students/MyProfile.js'));
const MySubscriptions =lazy(()=> import( './pages/students/MySubscriptions.js'));
//const StudentDashboardLayout =lazy(()=> import( './components/nav/StudentDashboardLayout.js'));


//const MockTests =lazy(()=> import( './pages/students/MockTests.js'));

//const QuestionList =lazy(()=> import( "./pages/students/QuestionList"));
//const QuestionDetail =lazy(()=> import( "./pages/students/QuestionDetail"));
//const QuestionForm =lazy(()=> import( "./pages/students/QuestionForm"));



// Admin Pages const
const AdminDashboard =lazy(()=> import( './pages/admin/adminDashboard.js'));
const BoardCreate =lazy(()=> import( './pages/admin/k12/board/CreateBoard'));
const ClassCreate =lazy(()=> import( './pages/admin/k12/class/ClassCreate'));
const BoardUpdate =lazy(()=> import( './pages/admin/k12/board/UpdateBoard'));
const ClassUpdate =lazy(()=> import( './pages/admin/k12/class/UpdateClass'));
const SubjectCreate =lazy(()=> import( './pages/admin/k12/subject/SubjectCreate.js'));
const SubjectUpdate =lazy(()=> import( './pages/admin/k12/subject/UpdateSubject.js'));
const ChapterCreate =lazy(()=> import( './pages/admin/k12/chapter/ChapterCreate.js'));
const ChapterUpdate =lazy(()=> import( './pages/admin/k12/chapter/ChapterUpdate.js'));
const TopicCreate =lazy(()=> import( './pages/admin/k12/topic/TopicCreate.js'));
const UpdateTopic =lazy(()=> import( './pages/admin/k12/topic/TopicUpdate.js'));
const ContentCreate =lazy(()=> import( './pages/admin/k12/content/ContentCreate.js'));
const ContentUpdate =lazy(()=> import( './pages/admin/k12/content/ContentUpdate.js'));
const BookCreate =lazy(()=> import( './pages/admin/k12/book/CreateBook.js'));
const UpdateBook =lazy(()=> import( './pages/admin/k12/book/UpdateBook.js'));
const CreateBookChapter =lazy(()=> import( './pages/admin/k12/chapter/CreateBookChapter.js'));
const ExamCreate =lazy(()=> import( './pages/admin/k12/exam/CreateExam.js'));
const CreateQuestion =lazy(()=> import( './pages/admin/k12/question/CreateQuestions.js'));
const CreateQuestionCsv =lazy(()=> import( './pages/admin/k12/question/CreateQuestionCsv.js'));
const UpdateQuestionCsv =lazy(()=> import( './pages/admin/k12/question/UpdateQuestionCsv.js'));
const ExamYear =lazy(()=> import( "./pages/admin/k12/exam/CreateExamYear.js"));
const CreateTest =lazy(()=> import( './pages/admin/k12/test/CreateTest.js'));
const CreateChapterTest =lazy(()=> import( './pages/admin/k12/test/CreateChapterTest.js'));
const CreateSubjectTest =lazy(()=> import( './pages/admin/k12/test/CreateSubjectTest.js'));
const BulkImageUpload =lazy(()=> import( './pages/admin/bulkImageUpload.js'));



const usePageTracking = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-X4BQ3T2KME', {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

const App = () => {
  const dispatch = useDispatch();
  usePageTracking(); // Initialize tracking

  // To check Firebase auth state
  useEffect(() => {
    dispatch(loadCart());
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        console.log("app.js -> user");
        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
    // Cleanup
    
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Suspense fallback = {
      <div className="col text-center p5">
      _Loading<LoadingOutlined/>_
      </div>
    }>
       <ErrorBoundary showDetails={process.env.NODE_ENV === 'development'}>
    <div className="main-container">
      {/* Header */}
      <Header />

      {/* Sidebar for Admin (if needed) */}
      <div className="content-wrapper">
        
          <ToastContainer />
          <Switch>
            {/* General Routes */}
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/register/complete" component={RegisterComplete} />
            <Route exact path="/forgot/password" component={ForgotPassword} />
            <Route exact path="/boards" component={BoardList} />
            <Route exact path="/boards/:_id" component={BoardPage} />
            <Route path="/classes" component={ClassList} />
            <Route exact path="/class/:_id" component={ClassPage} />
            <Route path="/subjects" component={SubjectList} />
            <Route exact path="/subject/:_id" component={SubjectDetailPage} />
            <Route path="/chapters" component={ChapterList} />
            <Route path="/chapter/:_id" component={ChapterPage} />
            <Route path="/topics" component={TopicList} />
            <Route path="/topic/:_id" component={TopicPage} />
            <Route path="/contents" component={ContentList} />
            <Route path="/content/:_id" component={ContentPage} />
            <Route path="/books" component={BookList} />
            <Route path="/book/:_id" component={BookSolutionPage} />
            <Route path="/exams" component={ExamList} />
            <Route path="/exam/:_id" component={Exam} />
            <Route path="/exam-questions" component={ExamQuestion} />
            <Route path="/questions" component={QuestionList} />
            <Route path="/user/questions" component={UserQuestionsPage} />
            <Route path="/user/question/:_id" component={UserQuestion} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/cart" component={Cart} />
            <Route path="/mocktests/:_id" component={MockTests} />
            <Route path="/livetests/:_id" component={MockTestsPreview} />
            <Route path="/livemocktest/instructions" component={MockTestsInstructions} />
            <Route path="/documentation" component={DocsPage} />
            


            {/* Student Routes */}
            <StudentRoute path="/user/edit-preferences" component={EditPreferences} />
            <StudentRoute exact path="/user/onboarding" component={Onboarding} />
            <StudentRoute exact path="/user/mycourses" component={MyCourses} />
            <StudentRoute exact path="/user/mysettings" component={MySettings} />
            <StudentRoute exact path="/user/mynotes" component={MyNotes} />
            <StudentRoute exact path="/user/my-questions" component={MyQuestions} />
            <StudentRoute exact path="/user/password-reset" component={PasswordReset} />
            <StudentRoute exact path="/checkout" component={Checkout} />
            <StudentRoute exact path="/payment" component={Payment} />
            <StudentRoute exact path="/user/purchase-history" component={History} />
            <StudentRoute exact path="/livemocktest/exam/:testId" component={AttemptTest} />
            <StudentRoute exact path="/test/results" component={TestResults} />
            <StudentRoute exact path="/user/profile" component={Profile} />
            <StudentRoute exact path="/user/sub-history" component={MySubscriptions} />
            <StudentRoute exact path="/user/mocktests" component={MockTestResults} />


            

            {/* Admin Routes */}
            <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
            <AdminRoute exact path="/admin/board" component={BoardCreate} />
            <AdminRoute exact path="/admin/board/:_id" component={BoardUpdate} />
            <AdminRoute exact path="/admin/class" component={ClassCreate} />
            <AdminRoute exact path="/admin/class/:_id" component={ClassUpdate} />
            <AdminRoute exact path="/admin/subject" component={SubjectCreate} />
            <AdminRoute exact path="/admin/subject/:_id" component={SubjectUpdate} />
            <AdminRoute exact path="/admin/chapter" component={ChapterCreate} />
            <AdminRoute exact path="/admin/chapter/:_id" component={ChapterUpdate} />
            <AdminRoute exact path="/admin/topic" component={TopicCreate} />
            <AdminRoute exact path="/admin/topic/:_id" component={UpdateTopic} />
            <AdminRoute exact path="/admin/content" component={ContentCreate} />
            <AdminRoute exact path="/admin/content/:_id" component={ContentUpdate} />
            <AdminRoute exact path="/admin/book" component={BookCreate} />
            <AdminRoute exact path="/admin/book/:_id" component={UpdateBook} />
            <AdminRoute exact path="/admin/bookChapter" component={CreateBookChapter} />
            <AdminRoute exact path="/admin/exam" component={ExamCreate} />
            <AdminRoute exact path="/admin/examYear" component={ExamYear} />
            <AdminRoute exact path="/admin/question" component={CreateQuestion} />
            <AdminRoute exact path="/admin/question-Bulk" component={CreateQuestionCsv} />
            <AdminRoute exact path="/admin/question-Bulk-Update" component={UpdateQuestionCsv} />
            <AdminRoute exact path="/admin/test" component={CreateTest} />
            <AdminRoute exact path="/admin/chaptertest" component={CreateChapterTest} />
            <AdminRoute exact path="/admin/subjecttest" component={CreateSubjectTest} />
            <AdminRoute exact path="/admin/image-upload" component={BulkImageUpload} />
          </Switch>
        </div>
      {/* Global ChatBot */}
     {/* <ChatBot />*/} 
    </div>
    </ErrorBoundary>
    </Suspense>
  );
};

export default App;
