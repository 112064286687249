import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFoQpoefqm2X7gyhaDe5D912m-blf7hBI",
  authDomain: "education4all-6ae6d.firebaseapp.com",
  projectId: "education4all-6ae6d",
  storageBucket: "education4all-6ae6d.appspot.com",
  messagingSenderId: "818968501364",
  appId: "1:818968501364:web:7ef42681d56293dc348521",
  measurementId: "G-X5FH1E7911"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

// Function to refresh token
async function refreshToken() {
  try {
    const user = auth.currentUser;
    if (user) {
      const newToken = await user.getIdToken(true); // Force refresh
      console.log("🔄 Token refreshed:");
    }
  } catch (error) {
    console.error("⚠️ Error refreshing token:", error);
  }
}

// Refresh token on any user interaction
function startActivityListener() {
  document.addEventListener("click", refreshToken);
  document.addEventListener("keydown", refreshToken);
}

// Auto-refresh token every 30 minutes
function startTokenRefreshInterval() {
  setInterval(async () => {
    if (auth.currentUser) {
      await refreshToken();
    }
  }, 30 * 60 * 1000); // Every 30 minutes
}

// Listen for auth state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    refreshToken(); // Immediate refresh on login
    startTokenRefreshInterval(); // Start auto-refresh
    startActivityListener(); // Detect user interactions
  }
});

// Export
export { auth, googleAuthProvider, refreshToken };
