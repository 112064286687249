import React, { useState, useEffect } from "react";
import { Button, Input, Spin } from "antd";
import { MessageOutlined, CloseOutlined } from "@ant-design/icons";
import { askChatBot } from "../../functions/chatbot"; // API function
import { useSelector } from "react-redux";
import { MathJax, MathJaxContext } from "better-react-mathjax"; // MathJax for equations
import "./ChatBot.css";

const ChatBot = ({ subjectId, chapterId, topicId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([{ text: `I can answer your questions related to the selected topic <img
        src="https://edutechtest.s3.ap-south-1.amazonaws.com/output-onlinegiftools.gif"
        alt="Gyani Baba Image"
        style="width: 50px; height: 50px; margin-left: 5px; vertical-align: middle;"
      />
    `, sender: "bot" }]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state); // Get logged-in user

  useEffect(() => {
    if (window.MathJax && window.MathJax.typeset) {
      window.MathJax.typeset();
    }
  }, [messages]);
  

  const handleSendMessage = async () => {
    if (!input.trim()) return; // Prevent empty messages
    setMessages([...messages, { text: input, sender: "user" }]);
    setLoading(true);

    try {
      console.log("FRONTEND FUNCTION NAME -> chatbot -> handleSendMessage", subjectId, chapterId, topicId);
      const response = await askChatBot(input, subjectId, chapterId, topicId, user?.token);
      
      // Format response (Convert Markdown & MathJax)
      let formattedResponse = response.answer
        .replace(/<think>.*?<\/think>/gs, "") // Remove <think> content
        .replace(/\n/g, "<br>"); // Convert new lines to HTML breaks
      
      setMessages((prev) => [...prev, { text: formattedResponse, sender: "bot" }]);
    } catch (error) {
      setMessages((prev) => [...prev, { text: "Error fetching response", sender: "bot" }]);
    } finally {
      setInput(""); // Clear input field
      setLoading(false);
      
    }
  };

  return (
    <MathJaxContext>
      <div className="chatbot-container">
        {!isOpen && (
          <Button className="chatbot-icon" type="primary" shape="circle" icon={<MessageOutlined />} size="large" onClick={() => setIsOpen(true)} />
        )}

        {isOpen && (
          <div className="chat-window">
            <div className="chat-header">
              <span>I'm Gyani Baba 🧙🏼‍♂️</span>
              <Button type="text" icon={<CloseOutlined />} onClick={() => setIsOpen(false)} />
            </div>

            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div key={index} className={`chat-message ${msg.sender}`}>
                  <MathJax>
                    <span dangerouslySetInnerHTML={{ __html: msg.text }} />
                  </MathJax>
                </div>
              ))}
              {loading && <Spin />}
            </div>

            <div className="chat-input">
              <Input value={input} onChange={(e) => setInput(e.target.value)} placeholder="Type your message..." onPressEnter={handleSendMessage} />
              <Button type="primary" onClick={handleSendMessage} disabled={loading}>Send</Button>
            </div>
          </div>
        )}
      </div>
    </MathJaxContext>
  );
};

export default ChatBot;
